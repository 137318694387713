define('ui/admin-tab/auth/activedirectory/controller', ['exports', 'ember', 'ui/utils/errors', 'ui/utils/constants'], function (exports, _ember, _uiUtilsErrors, _uiUtilsConstants) {

  var PLAIN_PORT = 389;
  var TLS_PORT = 636;

  exports['default'] = _ember['default'].Controller.extend({
    access: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    confirmDisable: false,
    errors: null,
    testing: false,

    providerName: 'ldap.providerName.ad',
    userType: _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_USER,
    groupType: _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_GROUP,
    ldapConfig: _ember['default'].computed.alias('model.ldapConfig'),

    addUserInput: '',
    addOrgInput: '',

    username: '',
    password: '',
    editing: false,
    advancedOpen: false,

    createDisabled: _ember['default'].computed('username.length', 'password.length', function () {
      return !this.get('username.length') || !this.get('password.length');
    }),

    numUsers: _ember['default'].computed('model.allowedIdentities.@each.externalIdType', 'userType', 'groupType', function () {
      return (this.get('model.allowedIdentities') || []).filterBy('externalIdType', this.get('userType')).get('length');
    }),

    numGroups: _ember['default'].computed('model.allowedIdentities.@each.externalIdType', 'userType', 'groupType', function () {
      return (this.get('model.allowedIdentities') || []).filterBy('externalIdType', this.get('groupType')).get('length');
    }),

    canEdit: _ember['default'].computed('access.enabled', 'editing', function () {
      var isEnabled = this.get('access.enabled');
      var editing = this.get('editing');

      if (isEnabled && editing || !isEnabled) {
        return true;
      } else if (isEnabled && !editing) {
        return false;
      }
    }),

    tlsChanged: _ember['default'].observer('ldapConfig.tls', function () {
      var on = this.get('ldapConfig.tls');
      var port = parseInt(this.get('ldapConfig.port'), 10);

      if (on && port === PLAIN_PORT) {
        this.set('ldapConfig.port', TLS_PORT);
      } else if (!on && port === TLS_PORT) {
        this.set('ldapConfig.port', PLAIN_PORT);
      }
    }),

    testConfig: function testConfig(data) {
      return this.get('authStore').request({
        url: 'testlogin',
        method: 'POST',
        data: data
      });
    },

    actions: {
      showAdvanced: function showAdvanced() {
        var open = this.get('advancedOpen');

        if (open) {
          _ember['default'].$('.custom-schema').hide();
          this.set('advancedOpen', false);
        } else {
          _ember['default'].$('.custom-schema').show();
          this.set('advancedOpen', true);
        }
      },
      edit: function edit() {
        this.toggleProperty('editing');
        this.set('originalModel', this.get('model').clone());
        this.set('username', this.get('model.identity.login'));
      },
      cancel: function cancel() {
        this.send('clearError');
        this.set('editing', false);
        this.set('model', this.get('originalModel'));
      },
      test: function test() {
        var _this = this;

        this.send('clearError');

        var model = this.get('model');
        var editing = this.get('editing');

        if (!editing) {
          model.setProperties({
            'provider': 'ldapconfig',
            'enabled': false, // It should already be, but just in case..
            'accessMode': 'unrestricted',
            'allowedIdentities': []
          });
        }

        var errors = model.validationErrors();

        var data = {
          type: 'testAuthConfig',
          authConfig: model,
          code: this.get('username') + ':' + this.get('password')
        };

        if (errors.get('length')) {
          this.set('errors', errors);
        } else {
          this.set('testing', true);

          if (editing) {
            this.testConfig(data).then(function () {
              model.save().then(function () {
                _this.send('waitAndRefresh');
              })['catch'](function (err) {
                _this.send('gotError', err);
              });
            })['catch'](function (err) {
              _this.send('gotError', err);
            });
          } else {
            this.set('testing', true);
            model.save().then(function () {
              _this.send('authenticate');
            })['catch'](function (err) {
              _this.send('gotError', err);
            });
          }
        }
      },

      authenticate: function authenticate() {
        var _this2 = this;

        this.send('clearError');

        var code = this.get('username') + ':' + this.get('password');

        this.get('access').login(code).then(function (res) {
          _this2.send('authenticationSucceeded', res.body);
        })['catch'](function (err) {
          _this2.send('gotError', err);
        });
      },

      authenticationSucceeded: function authenticationSucceeded(auth) {
        var _this3 = this;

        this.send('clearError');
        this.set('organizations', auth.orgs);

        // Set this to true so the token will be sent with the request
        this.set('access.enabled', true);

        var model = this.get('model');

        model.setProperties({
          'enabled': true,
          'accessMode': 'unrestricted',
          'allowedIdentities': [auth.userIdentity]
        });

        model.save().then(function () {
          _this3.send('waitAndRefresh');
        })['catch'](function (err) {
          _this3.set('access.enabled', false);
          _this3.send('gotError', err);
        });
      },

      waitAndRefresh: function waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show();

        setTimeout(function () {
          window.location.href = url || window.location.href;
        }, 1000);
      },

      promptDisable: function promptDisable() {
        this.set('confirmDisable', true);
        _ember['default'].run.later(this, function () {
          this.set('confirmDisable', false);
        }, 10000);
      },

      gotError: function gotError(err) {
        this.set('errors', [_uiUtilsErrors['default'].stringify(err)]);
        this.set('testing', false);
      },

      clearError: function clearError() {
        this.set('errors', null);
      },

      disable: function disable() {
        var _this4 = this;

        this.send('clearError');

        var model = this.get('model');
        model.setProperties({
          enabled: false
        });

        model.save().then(function () {
          _this4.get('access').clearSessionKeys();
          _this4.set('access.enabled', false);
          _this4.send('waitAndRefresh');
        })['catch'](function (err) {
          _this4.send('gotError', err);
        })['finally'](function () {
          _this4.set('confirmDisable', false);
        });
      }
    }
  });
});