define('ui/components/edit-projecttemplate/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'ui/utils/util', 'ui/utils/platform'], function (exports, _ember, _uiMixinsNewOrEdit, _uiUtilsUtil, _uiUtilsPlatform) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    access: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),

    projectTemplate: null,
    catalogInfo: null,

    stacksMap: null,
    orchestrationIds: null,
    activeOrchestration: null,
    primaryResource: _ember['default'].computed.alias('projectTemplate'),
    editing: _ember['default'].computed.notEmpty('projectTemplate.id'),

    onInit: (function () {
      this.initMap();
      this.initOrchestration();
      this.updateSupported();
    }).on('init'),

    actions: {
      selectOrchestration: function selectOrchestration(id) {
        var intl = this.get('intl');
        var map = this.get('stacksMap');
        var keys = Object.keys(map);

        for (var i = 0; i < keys.length; i++) {
          var obj = map[keys[i]];
          var tpl = obj.get('tpl');
          if (obj.get('enabled') && tpl && !tpl.supportsOrchestration(id)) {
            var orch = map[id].get('tpl.name');
            this.get('growl').error(intl.t('editProjectTemplate.error.conflict'), intl.t('editProjectTemplate.error.changing', {
              tplCategory: tpl.get('category'),
              stackName: tpl.get('name'),
              orchestration: (0, _uiUtilsUtil.ucFirst)(orch)
            }));
            return;
          }
        }

        this.get('orchestrationIds').forEach(function (cur) {
          if (map[cur]) {
            map[cur].set('enabled', id === cur);
          }
        });

        this.set('activeOrchestration', id);
        this.updateSupported();
      },

      editOrchestration: function editOrchestration() {
        var id = this.get('activeOrchestration');
        var obj = this.get('stacksMap')[id];
        this.send('enableStack', obj);
      },

      enableStack: function enableStack(obj) {
        var url = 'default';
        if (obj.stack.templateVersionId) {
          url = null;
        }

        this.get('modalService').toggleModal('catalog-configure', {
          serviceChoices: this.get('serviceChoices'),
          originalModel: obj,
          selectedTemplateUrl: url
        });
      },

      disableStack: function disableStack(obj, onlyAlternate) {
        if (onlyAlternate && !(0, _uiUtilsPlatform.isAlternate)(event)) {
          return false;
        }

        obj.set('enabled', false);
      },

      cancel: function cancel() {
        return this.get('router').transitionTo('settings.projects');
      }
    },

    initMap: function initMap() {
      var _this = this;

      var map = {};
      var orch = [];
      var stacks = this.get('projectTemplate.stacks');

      this.get('catalogInfo.catalog').forEach(function (tpl) {
        var tplId = tpl.get('id');
        var categories = tpl.get('categoryLowerArray');

        if (categories.includes('orchestration')) {
          orch.push(tpl.get('id'));
        }

        var cur = stacks.findBy('externalIdInfo.templateId', tplId);
        var required = categories.includes('framework');

        if (cur) {
          map[tplId] = _ember['default'].Object.create({
            required: required,
            enabled: true,
            compatible: null,
            tpl: tpl,
            stack: cur.clone()
          });
        } else {
          map[tplId] = _ember['default'].Object.create({
            required: required,
            enabled: false,
            tpl: tpl,
            compatible: null,
            stack: _this.get('store').createRecord({
              type: 'catalogTemplate',
              name: tpl.get('defaultName'),
              answers: {},
              templateId: tplId
            })
          });
        }
      });

      this.set('stacksMap', map);
      this.set('orchestrationIds', orch);
    },

    initOrchestration: function initOrchestration() {
      var map = this.get('stacksMap');

      var orch = 'cattle';
      this.get('orchestrationIds').forEach(function (key) {
        if (map[key] && _ember['default'].get(map[key], 'enabled')) {
          orch = key;
        }
      });

      this.set('activeOrchestration', orch);
    },

    updateSupported: function updateSupported() {
      var map = this.get('stacksMap');
      var orch = this.get('activeOrchestration');

      Object.keys(map).forEach(function (cur) {
        var obj = map[cur];
        if (!obj) {
          return;
        }
        var tpl = obj.get('tpl');
        if (!tpl) {
          return;
        }

        var supported = tpl.supportsOrchestration(orch);
        obj.set('supported', supported);
      });
    },

    categories: (function () {
      var out = [];
      this.get('catalogInfo.catalog').forEach(function (obj) {
        out.pushObjects(obj.get('categoryArray'));
      });
      out = (0, _uiUtilsUtil.uniqKeys)(out);

      out.removeObject('Orchestration');

      if (out.includes('Framework')) {
        // Move to the bottom
        out.removeObject('Framework');
        out.push('Framework');
      }
      return out;
    }).property('catalogInfo.catalog.@each.categoryArray'),

    showOrchestrationOrigin: false,
    orchestrationChoices: (function () {
      var active = this.get('activeOrchestration');

      var drivers = [{ name: 'cattle', title: 'Cattle', source: 'Built-In', image: this.get('app.baseAssets') + 'assets/images/logos/provider-orchestration.svg' }];

      var map = this.get('stacksMap');
      var seen = {};
      this.get('orchestrationIds').forEach(function (id) {
        var tpl = (map[id] || {}).tpl;
        if (tpl) {
          drivers.push({ name: id, title: tpl.name, source: 'in ' + (0, _uiUtilsUtil.ucFirst)(tpl.catalogId), image: tpl.links.icon });
          seen[tpl.name] = (seen[tpl.name] || 0) + 1;
        }
      });

      drivers.forEach(function (driver) {
        driver.active = active === driver.name;
      });

      var multiple = Object.keys(seen).map(function (k) {
        return seen[k];
      }).filter(function (x) {
        return x > 1;
      }).length > 0;
      this.set('showOrchestrationOrigin', multiple);

      return drivers;
    }).property('activeOrchestration'),

    willSave: function willSave() {
      var _this2 = this;

      var intl = this.get('intl');

      var out = this._super();
      if (!out) {
        return out;
      }

      var map = this.get('stacksMap');
      var orch = this.get('activeOrchestration');
      var ok = true;

      Object.keys(map).forEach(function (key) {
        var obj = map[key];
        var tpl = obj.get('tpl');
        if (obj.enabled && !tpl.supportsOrchestration(orch)) {
          _this2.get('growl').error(intl.t('editProjectTemplate.error.conflict'), intl.t('editProjectTemplate.error.enabling', {
            tplCategory: tpl.get('categoryArray.firstObject'),
            stackName: tpl.get('name'),
            orchestration: (0, _uiUtilsUtil.ucFirst)(orch)
          }));

          ok = false;
        }
      });

      return ok;
    },

    doSave: function doSave() {
      var map = this.get('stacksMap');
      var ary = [];

      // only look at enabled stacks
      Object.keys(map).forEach(function (key) {
        var obj = map[key];
        if (obj && obj.enabled) {
          var stack = obj.stack;
          if (stack.templateVersionId) {
            delete stack.templateId;
          } else {
            delete stack.templateVersionId;
          }

          ary.push(obj.stack);
        }
      });

      this.set('projectTemplate.stacks', ary);
      return this._super();
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});