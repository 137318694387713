define('ui/components/schema/input-secret/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    selected: null, // Selected secret ID
    selectClass: 'form-control',
    exclude: null, // ID or array of IDs to exclude from list
    valueKey: 'name', // What to set the value as.. 'name' or 'id'

    // For use as a catalog question
    field: null, // Read default from a schema resourceField
    value: null, // name or id output string

    allSecrets: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('allSecrets', this.get('store').all('secret'));

      var def = this.get('field.default');
      if (def && !this.get('selected')) {
        var exact;

        this.get('allSecrets').forEach(function (secret) {
          if (def === secret.get('name')) {
            exact = secret.get('id');
          }
        });

        this.set('selected', exact || null);
      }
    },

    filtered: (function () {
      var list = this.get('allSecrets');

      var exclude = this.get('exclude');
      if (exclude) {
        if (!_ember['default'].isArray(exclude)) {
          exclude = [exclude];
        }

        list = list.filter(function (x) {
          return !exclude.includes(x.id);
        });
      }

      return list.sortBy('name', 'id');
    }).property('allSecrets.[]', 'exclude.[]'),

    selectedChanged: (function () {
      var id = this.get('selected');
      var str = null;

      if (id) {
        var secret = this.get('store').getById('secret', id);
        if (secret) {
          str = secret.get(this.get('valueKey'));
        }
      }

      this.set('value', str);
    }).observes('selected')
  });
});