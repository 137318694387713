define('ui/helpers/array-includes', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    _haystack: null,

    shouldUpdate: _ember['default'].observer('_haystack.[]', function () {
      this.recompute();
    }),

    compute: function compute(params) {
      var haystack = params[0];
      var needle = params[1];

      if (!haystack) {
        return;
      }

      var _haystack = this.get('_haystack');
      if (haystack !== _haystack) {
        _haystack = new _ember['default'].A(haystack);
        this.set('_haystack', _haystack);
      }
      return _haystack.contains(needle);
    }
  });
});