define('ui/components/machine/driver-aliyunecs/component', ['exports', 'ember', 'ui/mixins/driver'], function (exports, _ember, _uiMixinsDriver) {

  var ioOptimized = [{
    value: "none"
  }, {
    value: "optimized"
  }];

  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    driverName: 'aliyunecs',
    aliyunecsConfig: _ember['default'].computed.alias('model.aliyunecsConfig'),
    ioOptimized: ioOptimized,

    bootstrap: function bootstrap() {
      var config = this.get('store').createRecord({
        type: 'aliyunecsConfig'
      });

      this.set('model', this.get('store').createRecord({
        type: 'host',
        aliyunecsConfig: config
      }));

      //this.set('editing', false);
    }

  });
});