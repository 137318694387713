define('ui/models/templateversion', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/constants'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsConstants) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    projects: _ember['default'].inject.service(),

    headers: (function () {
      return _defineProperty({}, _uiUtilsConstants['default'].HEADER.PROJECT_ID, this.get('projects.current.id'));
    }).property('project.current.id'),

    filesAsArray: (function () {
      var obj = this.get('files') || {};
      var out = [];

      Object.keys(obj).forEach(function (key) {
        out.push({ name: key, body: obj[key] });
      });

      return out;
    }).property('files'),

    supportsOrchestration: function supportsOrchestration(orch) {
      orch = orch.replace(/.*\*/, '');
      if (orch === 'k8s') {
        orch = 'kubernetes';
      }
      var list = ((this.get('labels') || {})[_uiUtilsConstants['default'].LABEL.ORCHESTRATION_SUPPORTED] || '').split(/\s*,\s*/).filter(function (x) {
        return x.length > 0;
      });
      return list.length === 0 || list.includes(orch);
    }
  });
});