define('ui/components/machine/driver-custom/component', ['exports', 'ember', 'ui/mixins/manage-labels', 'ui/utils/util'], function (exports, _ember, _uiMixinsManageLabels, _uiUtilsUtil) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], {
    settings: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    cattleAgentIp: null,
    model: null,

    actions: {
      cancel: function cancel() {
        this.attrs.cancel();
      },

      setLabels: function setLabels(labels) {
        if (this.get('model')) {
          var out = {};
          labels.forEach(function (row) {
            out[row.key] = row.value;
          });

          this.set('model.labels', out);
        }
      }
    },

    bootstrap: (function () {
      var _this = this;

      if (this.get('clonedModel')) {
        this.set('model', this.get('clonedModel'));
      } else {
        this.get('store').find('registrationToken', null, { filter: { state: 'active' }, forceReload: true }).then(function (tokens) {
          if (tokens.get('length') === 0) {
            // There should always be one already, but if there isn't go create one...
            var model = _this.get('store').createRecord({
              type: 'registrationToken'
            });
            _this.set('model', model);
            model.save();
          } else {
            _this.set('model', tokens.get('firstObject'));
          }
        });
      }
    }).on('init'),

    registrationCommand: (function () {
      var cmd = this.get('model.command');
      var cattleIp = this.get('cattleAgentIp');
      var lookFor = 'docker run';

      if (!cmd) {
        return null;
      }

      var idx = cmd.indexOf(lookFor);
      var env = _uiUtilsUtil['default'].addQueryParams('', this.get('model.labels') || {});

      if (env) {
        lookFor = 'docker run';
        idx = cmd.indexOf(lookFor);
        env = env.substr(1); // Strip off the leading '?'
        if (idx >= 0) {
          cmd = cmd.substr(0, idx + lookFor.length) + ' -e CATTLE_HOST_LABELS=\'' + env + '\' ' + cmd.substr(idx + lookFor.length);
        }
      }

      if (cattleIp) {
        if (idx >= 0) {
          cmd = cmd.substr(0, idx + lookFor.length) + ' -e CATTLE_AGENT_IP="' + cattleIp + '" ' + cmd.substr(idx + lookFor.length);
        }
      }

      return cmd;
    }).property('model.command', 'model.labels', 'cattleAgentIp'),

    registrationCommandWindows: (function () {
      var url = this.get('model.registrationUrl');

      return 'New-Item -Path \'C:\\Program Files\\rancher\' -Type Directory\nInvoke-WebRequest -UseBasicParsing \'https://github.com/rancher/agent/releases/download/v0.6.0/agent.exe\' -OutFile \'C:\\Program Files\\rancher\\agent.exe\'\n& \'C:\\Program Files\\rancher\\agent.exe\' -register-service ' + url + '\nStart-Service rancher-agent';
    }).property('model.command')

  });
});