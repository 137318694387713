define('ui/models/container', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/util', 'ember-api-store/utils/denormalize', 'ui/models/instance'], function (exports, _ember, _uiUtilsConstants, _uiUtilsUtil, _emberApiStoreUtilsDenormalize, _uiModelsInstance) {

  var Container = _uiModelsInstance['default'].extend({
    // Common to all instances
    requestedHostId: null,
    primaryIpAddress: null,
    primaryAssociatedIpAddress: null,
    projects: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),
    // Container-specific
    type: 'container',
    imageUuid: null,
    registryCredentialId: null,
    command: null,
    commandArgs: null,
    environment: null,
    ports: null,
    instanceLinks: null,
    dataVolumes: null,
    dataVolumesFrom: null,
    devices: null,
    restartPolicy: null,

    mounts: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('mountIds'),
    primaryHost: (0, _emberApiStoreUtilsDenormalize.denormalizeId)('hostId'),
    services: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('serviceIds'),
    primaryService: _ember['default'].computed.alias('services.firstObject'),
    primaryStack: _ember['default'].computed.alias('primaryService.stack'),

    actions: {
      restart: function restart() {
        return this.doAction('restart');
      },

      start: function start() {
        return this.doAction('start');
      },

      promptStop: function promptStop() {
        this.get('modalService').toggleModal('modal-container-stop', {
          model: [this]
        });
      },

      stop: function stop() {
        this.doAction('stop');
      },

      shell: function shell() {
        this.get('modalService').toggleModal('modal-shell', {
          model: this,
          escToClose: false
        });
      },

      popoutShell: function popoutShell() {
        var proj = this.get('projects.current.id');
        var id = this.get('id');
        _ember['default'].run.later(function () {
          window.open('//' + window.location.host + '/env/' + proj + '/infra/console?instanceId=' + id + '&isPopup=true', '_blank', "toolbars=0,width=717,height=590,left=200,top=200");
        });
      },

      popoutLogs: function popoutLogs() {
        var proj = this.get('projects.current.id');
        var id = this.get('id');
        _ember['default'].run.later(function () {
          window.open('//' + window.location.host + '/env/' + proj + '/infra/container-log?instanceId=' + id + '&isPopup=true', '_blank', "toolbars=0,width=700,height=715,left=200,top=200");
        });
      },

      logs: function logs() {
        this.get('modalService').toggleModal('modal-container-logs', this);
      },

      edit: function edit() {
        this.get('modalService').toggleModal('edit-container', this);
      },

      clone: function clone() {
        this.get('router').transitionTo('containers.new', { queryParams: { containerId: this.get('id') } });
      },

      cloneToService: function cloneToService() {
        this.get('router').transitionTo('service.new', { queryParams: { containerId: this.get('id') } });
      }

    },

    availableActions: (function () {
      var a = this.get('actionLinks');
      if (!a) {
        return [];
      }

      var labelKeys = Object.keys(this.get('labels') || {});
      var isSystem = !!this.get('system') || labelKeys.indexOf(_uiUtilsConstants['default'].LABEL.SYSTEM_TYPE) >= 0;
      var isService = labelKeys.indexOf(_uiUtilsConstants['default'].LABEL.SERVICE_NAME) >= 0;
      var isVm = this.get('isVm');
      var isK8s = labelKeys.indexOf(_uiUtilsConstants['default'].LABEL.K8S_POD_NAME) >= 0;

      var choices = [{ label: 'action.restart', icon: 'icon icon-refresh', action: 'restart', enabled: !!a.restart, bulkable: true, bulkActionName: 'Restart' }, { label: 'action.start', icon: 'icon icon-play', action: 'start', enabled: !!a.start, bulkable: true, bulkActionName: 'Start' }, { label: 'action.stop', icon: 'icon icon-stop', action: 'promptStop', enabled: !!a.stop, altAction: 'stop', bulkable: true, bulkActionName: 'Stop' }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: this.get('canDelete'), altAction: 'delete', bulkable: true, bulkActionName: 'Delete' }, { label: 'action.purge', icon: '', action: 'purge', enabled: !!a.purge }, { divider: true }, { label: 'action.execute', icon: '', action: 'shell', enabled: !!a.execute && !isVm, altAction: 'popoutShell' }, { label: 'action.console', icon: '', action: 'console', enabled: !!a.console && isVm, altAction: 'popoutShellVm' }, { label: 'action.logs', icon: '', action: 'logs', enabled: !!a.logs, altAction: 'popoutLogs' }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }, { divider: true }, { label: 'action.clone', icon: 'icon icon-copy', action: 'clone', enabled: !isSystem && !isService && !isK8s }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update && !isK8s }];

      return choices;
    }).property('actionLinks.{restart,start,stop,restore,purge,execute,logs,update}', 'systemContainer', 'canDelete', 'labels', 'isVm'),

    memoryReservationBlurb: _ember['default'].computed('memoryReservation', function () {
      if (this.get('memoryReservation')) {
        return (0, _uiUtilsUtil.formatSi)(this.get('memoryReservation'), 1024, 'iB', 'B');
      }
    }),
    // Hacks
    hasManagedNetwork: (function () {
      return this.get('primaryIpAddress') && this.get('primaryIpAddress').indexOf('10.') === 0;
    }).property('primaryIpAddress'),

    combinedState: (function () {
      var resource = this.get('state');
      var health = this.get('healthState');

      if (_uiUtilsConstants['default'].ACTIVEISH_STATES.indexOf(resource) >= 0) {
        if (health === null || health === 'healthy') {
          return resource;
        } else {
          return health;
        }
      } else if (resource === 'stopped' && (this.get('labels') || {})[_uiUtilsConstants['default'].LABEL.START_ONCE] && this.get('startCount') > 0) {
        return 'started-once';
      } else {
        return resource;
      }
    }).property('state', 'healthState'),

    isVm: (function () {
      return this.get('type').toLowerCase() === 'virtualmachine';
    }).property('type'),

    isOn: (function () {
      return ['running', 'updating-running', 'migrating', 'restarting'].indexOf(this.get('state')) >= 0;
    }).property('state'),

    displayIp: (function () {
      return this.get('primaryAssociatedIpAddress') || this.get('primaryIpAddress') || null;
    }).property('primaryIpAddress', 'primaryAssociatedIpAddress'),

    sortIp: (function () {
      var ip = this.get('primaryAssociatedIpAddress') || this.get('primaryIpAddress');
      if (!ip) {
        return '';
      }
      var match = ip.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);
      if (match) {
        return match.slice(1).map(function (octet) {
          return _uiUtilsUtil['default'].strPad(octet, 3, '0', false);
        }).join(".");
      }
    }).property('primaryIpAddress', 'primaryAssociatedIpAddress'),

    canDelete: (function () {
      return ['removed', 'removing', 'purging', 'purged'].indexOf(this.get('state')) === -1;
    }).property('state'),

    isManaged: _ember['default'].computed.notEmpty('systemContainer'),

    displayImage: (function () {
      return (this.get('imageUuid') || '').replace(/^docker:/, '');
    }).property('imageUuid'),

    displayExternalId: (function () {
      var id = this.get('externalId');
      if (id) {
        return (_ember['default'].Handlebars.Utils.escapeExpression(id.substr(0, 12)) + "&hellip;").htmlSafe();
      }
    }).property('externalId'),

    isGlobalScale: (function () {
      return (this.get('labels') || {})[_uiUtilsConstants['default'].LABEL.SCHED_GLOBAL] + '' === 'true';
    }).property('labels')
  });

  exports['default'] = Container;
});