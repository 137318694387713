define("ui/components/consumed-service-link/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 8,
                "column": 2
              }
            },
            "moduleName": "ui/components/consumed-service-link/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "if", [["subexpr", "eq", [["get", "link.service.stackId", ["loc", [null, [3, 16], [3, 36]]], 0, 0, 0, 0], ["get", "stackId", ["loc", [null, [3, 37], [3, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 12], [3, 45]]], 0, 0], "serviceLink.noAlias.sameStack", "serviceLink.noAlias.differentStack"], [], ["loc", [null, [3, 8], [3, 115]]], 0, 0]], ["stack", ["subexpr", "@mut", [["get", "link.service.displayStack", ["loc", [null, [4, 12], [4, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "link.service.displayName", ["loc", [null, [5, 14], [5, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "alias", ["subexpr", "@mut", [["get", "link.name", ["loc", [null, [6, 12], [6, 21]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 4], [7, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 4
                },
                "end": {
                  "line": 15,
                  "column": 4
                }
              },
              "moduleName": "ui/components/consumed-service-link/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "if", [["subexpr", "eq", [["get", "link.service.stackId", ["loc", [null, [10, 18], [10, 38]]], 0, 0, 0, 0], ["get", "stackId", ["loc", [null, [10, 39], [10, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 14], [10, 47]]], 0, 0], "serviceLink.withAlias.sameStack", "serviceLink.withAlias.differentStack"], [], ["loc", [null, [10, 10], [10, 121]]], 0, 0]], ["stack", ["subexpr", "@mut", [["get", "link.service.displayStack", ["loc", [null, [11, 14], [11, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "link.service.displayName", ["loc", [null, [12, 16], [12, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "alias", ["subexpr", "@mut", [["get", "link.name", ["loc", [null, [13, 14], [13, 23]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 6], [14, 8]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 4
                },
                "end": {
                  "line": 20,
                  "column": 4
                }
              },
              "moduleName": "ui/components/consumed-service-link/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "if", [["subexpr", "eq", [["get", "link.service.stackId", ["loc", [null, [16, 18], [16, 38]]], 0, 0, 0, 0], ["get", "stackId", ["loc", [null, [16, 39], [16, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 14], [16, 47]]], 0, 0], "serviceLink.noAlias.sameStack", "serviceLink.noAlias.differentStack"], [], ["loc", [null, [16, 10], [16, 117]]], 0, 0]], ["stack", ["subexpr", "@mut", [["get", "link.service.displayStack", ["loc", [null, [17, 14], [17, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "link.service.displayName", ["loc", [null, [18, 16], [18, 40]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [16, 6], [19, 8]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 2
              },
              "end": {
                "line": 21,
                "column": 2
              }
            },
            "moduleName": "ui/components/consumed-service-link/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "link.name", ["loc", [null, [9, 11], [9, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 4], [20, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "ui/components/consumed-service-link/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "link.service.name", ["loc", [null, [2, 13], [2, 30]]], 0, 0, 0, 0], ["get", "link.name", ["loc", [null, [2, 31], [2, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 9], [2, 41]]], 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [21, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "ui/components/consumed-service-link/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "link-to", ["service", ["get", "link.service.stackId", ["loc", [null, [1, 22], [1, 42]]], 0, 0, 0, 0], ["get", "link.service.id", ["loc", [null, [1, 43], [1, 58]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [22, 12]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});