define('ui/k8s-tab/kubectl/controller', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {

  var OLD_CONFIG_TPL = 'apiVersion: v1\nkind: Config\nclusters:\n- cluster:\n    api-version: v1%maybeInsecure%\n    server: "%baseUrl%/r/projects/%projectId%/kubernetes"\n  name: "%projectName%"\ncontexts:\n- context:\n    cluster: "%projectName%"\n    user: "%projectName%"\n  name: "%projectName%"\ncurrent-context: "%projectName%"\nusers:\n- name: "%projectName%"\n  user:\n    username: "%publicValue%"\n    password: "%secretValue%"';

  var NEW_CONFIG_TPL = 'apiVersion: v1\nkind: Config\nclusters:\n- cluster:\n    api-version: v1%maybeInsecure%\n    server: "%baseUrl%/r/projects/%projectId%/kubernetes:6443"\n  name: "%projectName%"\ncontexts:\n- context:\n    cluster: "%projectName%"\n    user: "%projectName%"\n  name: "%projectName%"\ncurrent-context: "%projectName%"\nusers:\n- name: "%projectName%"\n  user:\n    token: "%token%"';

  exports['default'] = _ember['default'].Controller.extend({
    access: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),
    k8s: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),

    step: 1,
    kubeconfig: '',

    downloadUrl: 'http://kubernetes.io/docs/user-guide/prereqs/',

    actions: {
      generate: function generate() {
        var _this = this;

        var supportsAuth = this.get('k8s.supportsAuth');
        this.set('step', 2);

        var name = this.get('access.identity.name');
        if (name) {
          name = 'kubectl: ' + name;
        } else {
          name = 'kubectl';
        }

        var storeName = undefined,
            tpl = undefined,
            accountId = undefined;
        if (supportsAuth) {
          tpl = NEW_CONFIG_TPL;
          storeName = 'userStore';
          accountId = this.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID);
        } else {
          tpl = OLD_CONFIG_TPL;
          storeName = 'store';
          accountId = this.get('projects.current.id');
        }

        this.get(storeName).createRecord({
          type: 'apiKey',
          accountId: accountId,
          name: name,
          description: 'Provides workstation access to kubectl'
        }).save().then(function (key) {
          var base = window.location.origin;
          var insecure = false;
          if (base.indexOf('http://') === 0) {
            base = base.replace(/^http:\/\//, 'https://');
            insecure = true;
          }

          var token = btoa('Basic ' + btoa(key.get('publicValue') + ':' + key.get('secretValue')));

          var config = tpl.replace(/%baseUrl%/g, base).replace(/%maybeInsecure%/g, insecure ? '\n    insecure-skip-tls-verify: true' : '').replace(/%projectName%/g, _this.get('projects.current.displayName')).replace(/%projectId%/g, _this.get('projects.current.id')).replace(/%publicValue%/g, key.get('publicValue')).replace(/%secretValue%/g, key.get('secretValue')).replace(/%token%/g, token);

          _this.set('kubeconfig', config);
          _this.set('step', 3);
        })['catch'](function (err) {
          _this.set('step', 1);
          _this.get('growl').fromError('Error creating API Key', err);
        });
      }
    }
  });
});