define('ui/components/form-service-links/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    service: null,
    withAlias: true,
    serviceLinksArray: null,

    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      var out = [];
      var links;
      if (this.get('service.id')) {
        // Edit
        links = this.get('service.consumedServicesWithNames') || [];
      } else {
        // New / Clone
        links = this.get('service.serviceLinks') || [];
      }

      links.forEach(function (obj) {
        var name = obj.get('name');
        var service = obj.get('service');

        out.push(_ember['default'].Object.create({
          name: name === service.get('name') ? '' : name,
          obj: service,
          serviceId: service.get('id')
        }));
      });

      this.set('serviceLinksArray', out);
      this.serviceLinksArrayDidChange();
    },

    serviceLinksArrayDidChange: (function () {
      this.sendAction('changed', this.get('serviceLinksArray'));
    }).observes('serviceLinksArray.@each.{name,serviceId}'),

    actions: {
      addServiceLink: function addServiceLink() {
        this.get('serviceLinksArray').pushObject(_ember['default'].Object.create({ name: '', serviceId: null }));
      },
      removeServiceLink: function removeServiceLink(obj) {
        this.get('serviceLinksArray').removeObject(obj);
      }
    }
  });
});