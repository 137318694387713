define('ui/components/edit-host/component', ['exports', 'ember', 'ui/mixins/manage-labels', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base', 'ui/utils/constants', 'ui/utils/debounce'], function (exports, _ember, _uiMixinsManageLabels, _uiMixinsNewOrEdit, _lacssoComponentsModalBase, _uiUtilsConstants, _uiUtilsDebounce) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], _uiMixinsManageLabels['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    model: null,
    editing: true,

    ips: null,
    requireAny: null,
    requiredIfAny: _defineProperty({}, _uiUtilsConstants['default'].LABEL.SYSTEM_TYPE, ''),
    systemLabels: null,
    userLabels: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('originalModel').clone());
      this.initLabels(this.get('model.labels'), null, [_uiUtilsConstants['default'].LABEL.SCHED_IPS, _uiUtilsConstants['default'].LABEL.REQUIRE_ANY]);

      var ips = [];
      var str = this.getLabel(_uiUtilsConstants['default'].LABEL.SCHED_IPS);
      if (str) {
        ips = str.split(/\s*,\s*/).filter(function (x) {
          return x.length;
        });
      }
      this.set('ips', ips);

      this.set('requireAny', this.getLabel(_uiUtilsConstants['default'].LABEL.REQUIRE_ANY));
    },

    ipsChanged: (function () {
      var ips = (this.get('ips') || []).map(function (x) {
        return x.trim();
      }).filter(function (x) {
        return x.length;
      });
      this.setLabel(_uiUtilsConstants['default'].LABEL.SCHED_IPS, ips.join(', '));
    }).observes('ips.[]'),

    requireAnyChanged: (function () {
      var any = this.get('requireAny');
      this.setLabel(_uiUtilsConstants['default'].LABEL.REQUIRE_ANY, any || undefined);
    }).observes('requireAny'),

    updateLabels: function updateLabels(labels) {
      this.set('systemLabels', labels);
    },

    mergeAllLabels: (0, _uiUtilsDebounce.debouncedObserver)('systemLabels.@each.{key,value}', 'userLabels.@each.{key,value}', function () {
      var out = (0, _uiMixinsManageLabels.flattenLabelArrays)(this.get('systemLabels'), this.get('userLabels'));

      this.set('model.labels', out);
    }),

    actions: {
      setUserLabels: function setUserLabels(labels) {
        this.set('userLabels', labels);
      }
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});