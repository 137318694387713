define('ui/models/machinedriver', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/mixins/cattle-polled-resource', 'ui/utils/constants', 'ui/utils/parse-externalid'], function (exports, _ember, _emberApiStoreModelsResource, _uiMixinsCattlePolledResource, _uiUtilsConstants, _uiUtilsParseExternalid) {

  var builtInUi = ['amazonec2', 'azure', 'digitalocean', 'exoscale', 'packet', 'rackspace', 'ubiquity', 'vmwarevsphere', 'aliyunecs'];

  function displayUrl(url) {
    url = url || '';
    var parts = url.split('/');
    var out = null;

    if (parts.length < 2) {
      return url;
    }

    if (url.indexOf('github.com') >= 0) {
      out = '.../' + parts[parts.length - 2] + '/' + parts[parts.length - 1];
    } else {
      out = url;
    }
    return out;
  }

  var machineDriver = _emberApiStoreModelsResource['default'].extend(_uiMixinsCattlePolledResource['default'], {
    type: 'machineDriver',
    modalService: _ember['default'].inject.service('modal'),
    catalog: _ember['default'].inject.service(),

    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },

      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },

      edit: function edit() {
        this.get('modalService').toggleModal('modal-edit-driver', this);
      }
    },

    catalogTemplateIcon: _ember['default'].computed('externalId', function () {
      var parsedExtId = (0, _uiUtilsParseExternalid.parseExternalId)(this.get('externalId')) || null;

      if (!parsedExtId) {
        return null;
      }

      return this.get('catalog').getTemplateFromCache(parsedExtId.templateId).get('links.icon');
    }),

    iconMapFromConstants: _ember['default'].computed('name', function () {
      var name = this.get('name').toUpperCase();
      var icon = _uiUtilsConstants['default'].MACHINE_DRIVER_IMAGES[name];

      if (icon) {
        return icon;
      } else {
        return _uiUtilsConstants['default'].MACHINE_DRIVER_IMAGES.GENERIC;
      }
    }),

    displayUrl: (function () {
      return displayUrl(this.get('url'));
    }).property('url'),

    displayChecksum: _ember['default'].computed('checksum', function () {
      return this.get('checksum').substring(0, 8);
    }),

    displayUiUrl: (function () {
      return displayUrl(this.get('uiUrl'));
    }).property('uiUrl'),

    hasBuiltinUi: (function () {
      return builtInUi.indexOf(this.get('name')) >= 0;
    }).property('name'),

    isCustom: (function () {
      return !this.get('builtin') && !this.get('externalId');
    }).property('builtin', 'externalId'),

    hasUi: (function () {
      return this.get('hasBuiltinUi') || !!this.get('uiUrl');
    }).property('hasBuiltinUi'),

    newExternalId: (function () {
      var externalId = _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG + _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SEPARATOR + this.get('selectedTemplateModel.id');
      return externalId;
    }).property('isSystem', 'selectedTemplateModel.id'),

    availableActions: (function () {
      var a = this.get('actionLinks');
      var builtin = !!this.get('builtin');

      return [{ label: 'action.activate', icon: 'icon icon-play', action: 'activate', enabled: !!a.activate }, { label: 'action.deactivate', icon: 'icon icon-pause', action: 'deactivate', enabled: !!a.deactivate }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update && !builtin }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove && !builtin, altAction: 'delete' }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];
    }).property('actionLinks.{update,activate,deactivate,remove}', 'builtin'),

    externalIdInfo: (function () {
      return (0, _uiUtilsParseExternalid.parseExternalId)(this.get('externalId'));
    }).property('externalId')
  });

  machineDriver.reopenClass({
    // Drivers don't get pushed by /subscribe WS, so refresh more often
    pollTransitioningDelay: 2000,
    pollTransitioningInterval: 1000,
    pollTransitioningIntervalMax: 60000,
    pollTransitioningIntervalFactor: 1.5
  });

  exports['default'] = machineDriver;
});