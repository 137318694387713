define('ui/components/new-container/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'ui/mixins/select-tab', 'ui/utils/debounce', 'ui/utils/constants', 'ui/utils/util', 'ui/mixins/manage-labels'], function (exports, _ember, _uiMixinsNewOrEdit, _uiMixinsSelectTab, _uiUtilsDebounce, _uiUtilsConstants, _uiUtilsUtil, _uiMixinsManageLabels) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], _uiMixinsSelectTab['default'], {
    intl: _ember['default'].inject.service(),

    isStandalone: true,
    isService: false,
    isSidekick: false,
    isUpgrade: false,
    primaryResource: null,
    primaryService: null,
    launchConfig: null,
    service: null,
    allHosts: null,
    allStoragePools: null,

    serviceLinksArray: null,
    isGlobal: null,
    isRequestedHost: null,
    portsAsStrArray: null,
    launchConfigIndex: -1,
    upgradeOptions: null,

    // Errors from components
    commandErrors: null,
    volumeErrors: null,
    networkingErrors: null,
    secretErrors: null,
    healthCheckErrors: null,
    schedulingErrors: null,
    securityErrors: null,
    scaleErrors: null,
    imageErrors: null,
    portErrors: null,
    diskErrors: null,
    activeLaunchConfigIndex: -1,

    actions: {
      selectLaunchConfig: function selectLaunchConfig(index) {
        this.set('activeLaunchConfigIndex', index);
        if (this.$()) {
          this.$().children('[data-launchindex]').addClass('hide');
          var body = this.$().children('[data-launchindex="' + index + '"]')[0];
          if (body) {
            $(body).removeClass('hide');
            $("INPUT[type='text']", body)[0].focus();
          }
        }
      },

      addSidekick: function addSidekick(vm) {
        var _this = this;

        var ary = this.get('service.secondaryLaunchConfigs');
        ary.pushObject(this.get('store').createRecord({
          type: 'secondaryLaunchConfig',
          kind: vm === true ? 'virtualMachine' : 'container',
          tty: true,
          stdinOpen: true,
          restartPolicy: { name: 'always' },
          labels: _defineProperty({}, _uiUtilsConstants['default'].LABEL.PULL_IMAGE, _uiUtilsConstants['default'].LABEL.PULL_IMAGE_VALUE),
          uiId: _uiUtilsUtil['default'].randomStr()
        }));

        // Wait for it to be added to the DOM...
        _ember['default'].run.next(function () {
          _this.send('selectLaunchConfig', ary.get('length') - 1);
        });
      },

      removeSidekick: function removeSidekick() {
        var _this2 = this;

        var idx = this.get('activeLaunchConfigIndex');
        var ary = this.get('service.secondaryLaunchConfigs');
        ary.removeAt(idx);

        // If you remove the last one, go to the previous one
        if (idx >= ary.get('length')) {
          idx = ary.get('length') - 1;
        }

        _ember['default'].run.next(function () {
          _this2.send('selectLaunchConfig', idx);
        });
      },

      setScale: function setScale(scale) {
        this.set('service.scale', scale);
      },

      setImage: function setImage(uuid) {
        this.set('launchConfig.imageUuid', uuid);
      },

      setLabels: function setLabels(section, labels) {
        this.set(section + 'Labels', labels);
      },

      setRequestedHostId: function setRequestedHostId(hostId) {
        this.set('launchConfig.requestedHostId', hostId);
      },

      setGlobal: function setGlobal(bool) {
        this.set('isGlobal', bool);
      },

      setServiceLinks: function setServiceLinks(links) {
        this.set('serviceLinksArray', links);
      },

      setUpgrade: function setUpgrade(upgrade) {
        this.set('upgradeOptions', upgrade);
      },

      done: function done() {
        this.sendAction('done');
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('launchConfig.secrets')) {
        this.set('launchConfig.secrets', []);
      }

      this.labelsChanged();
    },

    didInsertElement: function didInsertElement() {
      if (this.get('isVm')) {
        this.send('selectTab', 'disks');
      } else {
        this.send('selectTab', 'command');
      }

      this.$("INPUT[type='text']")[0].focus();
    },

    hasSidekicks: (function () {
      return this.get('service.secondaryLaunchConfigs.length') > 0;
    }).property('service.secondaryLaunchConfigs.length'),

    activeLaunchConfig: (function () {
      var idx = this.get('launchConfigIndex');
      if (idx === -1) {
        return this.get('launchConfig');
      } else {
        return this.get('service.secondaryLaunchConfigs').objectAt(idx);
      }
    }).property('launchConfigIndex'),

    launchConfigChoices: (function () {
      var isUpgrade = this.get('isUpgrade');
      var intl = this.get('intl');

      // Enabled is only for upgrade, and isn't maintained if the names change, but they can't on upgrade.
      var out = [{
        index: -1,
        name: this.get('service.name') || intl.t('newContainer.emptyPrimaryService'),
        enabled: true
      }];

      (this.get('service.secondaryLaunchConfigs') || []).forEach(function (item, index) {
        out.push({
          index: index,
          name: item.get('name') || intl.t('newContainer.emptySidekick', { num: index + 1 }),
          enabled: !isUpgrade
        });
      });

      return out;
    }).property('service.name', 'service.secondaryLaunchConfigs.@each.name', 'intl._locale'),

    noLaunchConfigsEnabled: (function () {
      return this.get('launchConfigChoices').filterBy('enabled', true).get('length') === 0;
    }).property('launchConfigChoices.@each.enabled'),

    activeLabel: (function () {
      var idx = this.get('launchConfigIndex');
      var str = '';

      if (this.get('hasSidekicks')) {
        if (idx === -1) {
          str = 'Primary Service: ';
        } else {
          str += 'Sidekick Service: ';
        }
      }

      if (idx === -1) {
        if (this.get('service.name')) {
          str += this.get('service.name');
        } else {
          str += '(No name)';
        }
      } else {
        if (this.get('activeLaunchConfig.name')) {
          str += this.get('activeLaunchConfig.name');
        } else {
          str += '(Sidekick #' + (idx + 1) + ')';
        }
      }

      return str;
    }).property('service.name', 'activeLaunchConfig.name', 'launchConfigIndex', 'hasSidekicks'),

    // ----------------------------------
    // Labels
    // ----------------------------------
    userLabels: null,
    scaleLabels: null,
    imageLabels: null,
    commandLabels: null,
    schedulingLabels: null,
    networkingLabels: null,

    labelsChanged: (0, _uiUtilsDebounce.debouncedObserver)('userLabels.@each.{key,value}', 'scaleLabels.@each.{key,value}', 'imageLabels.@each.{key,value}', 'commandLabels.@each.{key,value}', 'schedulingLabels.@each.{key,value}', 'networkingLabels.@each.{key,value}', function () {
      var out = (0, _uiMixinsManageLabels.flattenLabelArrays)(this.get('userLabels'), this.get('scaleLabels'), this.get('imageLabels'), this.get('commandLabels'), this.get('schedulingLabels'), this.get('networkingLabels'));

      var config = this.get('launchConfig');
      if (config) {
        this.set('launchConfig.labels', out);
      }
    }),

    // ----------------------------------
    // Disks
    // ----------------------------------
    storageDriverChoices: (function () {
      return (this.get('allStoragePools') || []).map(function (pool) {
        return pool.get('driverName');
      }).filter(function (name) {
        return _uiUtilsConstants['default'].VM_CAPABLE_STORAGE_DRIVERS.indexOf(name) >= 0;
      }).uniq().sort();
    }).property('allStoragePools.@each.driverName'),

    // ----------------------------------
    // Save
    // ----------------------------------
    validate: function validate() {
      this._super();
      var errors = this.get('errors') || [];

      if (this.get('isService')) {
        (this.get('service.secondaryLaunchConfigs') || []).forEach(function (slc) {
          slc.validationErrors().forEach(function (err) {
            errors.push(slc.get('displayName') + ': ' + err);
          });
        });
      }

      // Errors from components
      errors.pushObjects(this.get('commandErrors') || []);
      errors.pushObjects(this.get('volumeErrors') || []);
      errors.pushObjects(this.get('networkingErrors') || []);
      errors.pushObjects(this.get('secretErrors') || []);
      errors.pushObjects(this.get('healthCheckErrors') || []);
      errors.pushObjects(this.get('schedulingErrors') || []);
      errors.pushObjects(this.get('securityErrors') || []);
      errors.pushObjects(this.get('scaleErrors') || []);
      errors.pushObjects(this.get('imageErrors') || []);
      errors.pushObjects(this.get('portErrors') || []);
      errors.pushObjects(this.get('diskErrors') || []);

      errors = errors.uniq();

      if (errors.get('length')) {
        this.set('errors', errors);
        return false;
      }

      this.set('errors', null);
      return true;
    },

    doSave: function doSave() {
      var _this3 = this,
          _arguments = arguments;

      if (this.get('isService') && this.get('isUpgrade')) {
        var choices;
        var primary;
        var slc;
        var secondaries;

        var _ret = (function () {
          choices = _this3.get('launchConfigChoices');
          primary = null;
          slc = [];
          secondaries = _this3.get('service.secondaryLaunchConfigs');

          choices.filterBy('enabled', true).forEach(function (choice) {
            if (choice.index === -1) {
              primary = _this3.get('service.launchConfig');
            } else {
              slc.push(secondaries.objectAt(choice.index).serialize());
            }
          });

          var service = _this3.get('service');
          return {
            v: _this3._super.apply(_this3, _arguments).then(function () {
              return service.waitForAction('upgrade').then(function () {
                return service.doAction('upgrade', {
                  inServiceStrategy: {
                    batchSize: _this3.get('upgradeOptions.batchSize'),
                    intervalMillis: _this3.get('upgradeOptions.intervalMillis'),
                    startFirst: _this3.get('upgradeOptions.startFirst'),
                    launchConfig: primary,
                    secondaryLaunchConfigs: slc
                  }
                });
              });
            })
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } else {
        return this._super.apply(this, arguments);
      }
    },

    didSave: function didSave() {
      if (this.get('isService')) {
        // Returns a promise
        return this.setServiceLinks();
      }
    },

    setServiceLinks: function setServiceLinks() {
      var service = this.get('service');
      var ary = [];
      this.get('serviceLinksArray').forEach(function (row) {
        if (row.serviceId) {
          ary.push({ name: row.name, serviceId: row.serviceId });
        }
      });

      return service.doAction('setservicelinks', { serviceLinks: ary });
    },

    doneSaving: function doneSaving() {
      this.sendAction('done');
    },

    headerLabel: (function () {
      var k = 'newContainer.';
      k += (this.get('isUpgrade') ? 'upgrade' : 'add') + '.';
      if (this.get('isService')) {
        k += 'service';
      } else if (this.get('isVm')) {
        k += 'vm';
      } else {
        k += 'container';
      }

      var count = this.get('service.secondaryLaunchConfigs.length') + 1;

      return this.get('intl').t(k, { numServices: count });
    }).property('intl._locale', 'isUpgrade', 'isService', 'isVm', 'service.secondaryLaunchConfigs.length'),

    supportsSecrets: (function () {
      return !!this.get('store').getById('schema', 'secret');
    }).property()
  });
});