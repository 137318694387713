define("ui/k8s-tab/kubectl/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 2
            },
            "end": {
              "line": 9,
              "column": 2
            }
          },
          "moduleName": "ui/k8s-tab/kubectl/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-primary");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["k8sTab.kubectl.stepOne.generateConfigText"], ["downloadUrl", ["subexpr", "@mut", [["get", "downloadUrl", ["loc", [null, [7, 85], [7, 96]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 7], [7, 98]]], 0, 0], ["element", "action", ["generate"], [], ["loc", [null, [8, 12], [8, 33]]], 0, 0], ["inline", "t", ["k8sTab.kubectl.stepOne.generateButton"], [], ["loc", [null, [8, 58], [8, 103]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "ui/k8s-tab/kubectl/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "icon icon-spinner icon-spin");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["k8sTab.kubectl.stepTwo.progress"], [], ["loc", [null, [11, 48], [11, 87]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 18,
              "column": 2
            }
          },
          "moduleName": "ui/k8s-tab/kubectl/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("pre");
          var el2 = dom.createElement("code");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "r-mt10 r-mb0");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 0]), 0, 0);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [7]), 0, 0);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["k8sTab.kubectl.stepThree.helpText"], [], ["loc", [null, [14, 7], [14, 66]]], 0, 0], ["content", "kubeconfig", ["loc", [null, [15, 15], [15, 29]]], 0, 0, 0, 0], ["inline", "copy-to-clipboard", [], ["tooltipText", "", "buttonText", "copyToClipboard.tooltip", "clipboardText", ["subexpr", "@mut", [["get", "kubeconfig", ["loc", [null, [16, 90], [16, 100]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "with-clip"], ["loc", [null, [16, 4], [16, 120]]], 0, 0], ["inline", "format-html-message", ["k8sTab.kubectl.stepThree.downloadText"], ["downloadUrl", ["subexpr", "@mut", [["get", "downloadUrl", ["loc", [null, [17, 102], [17, 113]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 28], [17, 115]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 0
          }
        },
        "moduleName": "ui/k8s-tab/kubectl/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2, "style", "display: block");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "well");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "well");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 2, 2);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["k8sTab.kubectl.header"], [], ["loc", [null, [2, 29], [2, 58]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "step", ["loc", [null, [6, 12], [6, 16]]], 0, 0, 0, 0], 1], [], ["loc", [null, [6, 8], [6, 19]]], 0, 0]], [], 0, null, ["loc", [null, [6, 2], [9, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "step", ["loc", [null, [10, 12], [10, 16]]], 0, 0, 0, 0], 2], [], ["loc", [null, [10, 8], [10, 19]]], 0, 0]], [], 1, null, ["loc", [null, [10, 2], [12, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "step", ["loc", [null, [13, 12], [13, 16]]], 0, 0, 0, 0], 3], [], ["loc", [null, [13, 8], [13, 19]]], 0, 0]], [], 2, null, ["loc", [null, [13, 2], [18, 9]]]], ["inline", "format-html-message", ["k8sTab.kubectl.shell.helpText"], [], ["loc", [null, [22, 5], [22, 60]]], 0, 0], ["inline", "container-shell", [], ["classNames", "inline-block", "command", ["subexpr", "@mut", [["get", "model.command", ["loc", [null, [26, 14], [26, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "instance", ["subexpr", "@mut", [["get", "model.instance", ["loc", [null, [27, 15], [27, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "cols", 120, "rows", 30, "showProtip", false], ["loc", [null, [24, 2], [31, 4]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});