define('ui/components/webhook/service-upgrade-config/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    actions: {
      optionsChanged: function optionsChanged(opt) {
        this.get('model').setProperties(opt);
      }
    }
  });
});