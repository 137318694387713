define('ui/components/modal-shell/component', ['exports', 'ember', 'lacsso/components/modal-base'], function (exports, _ember, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'large-modal', 'modal-shell'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts.model'),
    init: function init() {
      this._super.apply(this, arguments);
      this.shortcuts.disable();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.shortcuts.enable();
    }

  });
});