define('ui/components/webhook/new-receiver/component', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {

  var DRIVERS = ['scaleService', 'scaleHost', 'serviceUpgrade'];

  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    model: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.swapConfig(this.get('model.driver'));
    },

    swapConfig: function swapConfig(driver) {
      var _this = this;

      var store = this.get('webhookStore');

      // Clear the config of the inactive drivers
      DRIVERS.forEach(function (name) {
        if (name !== driver) {
          _this.set('model.' + name + 'Config', null);
        }
      });

      var def = undefined;
      switch (driver) {
        case 'scaleService':
          def = store.createRecord({
            type: 'scaleService',
            action: 'up',
            amount: 1,
            serviceId: null
          });
          break;
        case 'scaleHost':
          def = store.createRecord({
            type: 'scaleHost',
            action: 'up',
            amount: 1,
            hostSelector: '',
            deleteOption: 'leastRecent'
          });
          break;
        case 'serviceUpgrade':
          def = store.createRecord({
            type: 'serviceUpgrade',
            tag: '',
            serviceSelector: '',
            batchSize: 1,
            interval: 2,
            startFirst: false
          });
          break;
      }

      if (!this.get('model.' + driver + 'Config')) {
        this.set('model.' + driver + 'Config', def);
      }
    },

    scaleHostActionChanged: (function () {
      var action = this.get('model.scaleHostConfig.action');
      if (action === 'up') {
        this.set('model.scaleHostConfig.deleteOption', null);
      } else if (action === 'down' && !this.get('model.scaleHostConfig.deleteOption')) {
        this.set('model.scaleHostConfig.deleteOption', 'leastRecent');
      }
    }).observes('model.scaleHostConfig.action'),

    actions: {
      changeDriver: function changeDriver(e) {
        var driver = e.target.value;
        this.swapConfig(driver);
        this.set('model.driver', driver);
        this.set('errors', null);
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    doSave: function doSave(opt) {
      opt = opt || {};
      if (!this.get('primaryResource.id')) {
        opt.url = 'receivers';
      }

      return this._super(opt);
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});